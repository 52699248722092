import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import CraftImage from "../craftcms/craft-image"
import { StaticImage } from "gatsby-plugin-image"
import "./designed-block.scss"

// http://staging-static.vmhost.psu.edu/components/designed-block.php

const DesignedBlock = ({ id, blocks }) => (
  <section className="designed-block-wrap">
    <div className="container ">
      <div className="designed-block">
        {blocks.map(block => {
          // console.log('block: ', block);

          return (
            <div className="block" key={block.id}>
              <div
                className="block-image "
                // style={{
                // 	background:
                // 		"url('img/designed-block-overlay.png') top left no-repeat",
                // 	backgroundSize: '100%'
                // }}
              >
                {/* <Link to={block.linkUrl} className="ratio ratio-4x3"> */}
                <Link to={block.linkUrl} className="">
                  <h2 className="">{block.heading}</h2>
                  {/* <img
										src="/components/img/new-kensington.png"
										alt=""
									/> */}
                  <CraftImage
                    srcset={block.image[0].srcset}
                    alt={block.image[0].title}
                    url={block.image[0].url}
                    className=""
                    loading="lazy"
                    sizes="(min-width: 768px) 40vw, (min-width: 1080px) 25vw, 80vw"
                  />
                </Link>
              </div>

              <div
                className="block-content text-center"
                dangerouslySetInnerHTML={{
                  __html: block.body,
                }}
              ></div>
            </div>
          )
        })}
      </div>
    </div>
  </section>
)

DesignedBlock.propTypes = {
  heading: PropTypes.string,
  subheading: PropTypes.string,
  children: PropTypes.node,
  image: PropTypes.object,
  links: PropTypes.array,
}

DesignedBlock.defaultProps = {
  heading: `Explore`,
  subheading: `systems and components that make up modern dairy`,
  children: <p></p>,
  image: {
    extension: "jpg",
    filename: "150-farm.jpg",
    focalPoint: [0.5, 0.5],
    hasFocalPoint: false,
    path: "150-farm.jpg",
    title: "150 farm",
    width: 3840,
    url: "//virtual-farm:8890/uploads/150-farm.jpg",
    srcset:
      "//virtual-farm:8890/uploads/_600xAUTO_crop_center-center_none/150-farm.jpg 600w, //virtual-farm:8890/uploads/_900xAUTO_crop_center-center_none/150-farm.jpg 900w, //virtual-farm:8890/uploads/_1200xAUTO_crop_center-center_none/150-farm.jpg 1200w, //virtual-farm:8890/uploads/_1500xAUTO_crop_center-center_none/150-farm.jpg 1500w, //virtual-farm:8890/uploads/_1800xAUTO_crop_center-center_none/150-farm.jpg 1800w",
  },
  links: [],
}

export default DesignedBlock
