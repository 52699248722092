import * as React from "react"
import PropTypes from "prop-types"
// import { Link } from "gatsby"
import CraftImage from "../craftcms/craft-image"
import "./hero-wrap.scss"
import "./hero-bespoke.scss"

// http://staging-static.vmhost.psu.edu/components/homepage-hero-bespoke-still.php

const HeroBespoke = ({
  image,
  heading,
  subheading,
  children,
  callToAction,
  callToActionLink,
}) => (
  <section className="hero-wrap bespoke">
    {/* <img src="img/homepage-hero/homepage-hero-test-3.jpg" alt="" /> */}
    {/* {console.log('image[0]: ', image)} */}
    {/* {console.log('image[0].srcset: ', image[0].srcset)} */}
    {typeof image[0] !== "undefined" &&
      typeof image[0].srcset !== "undefined" && (
        <CraftImage
          srcset={image[0].srcset}
          alt={image[0].title}
          url={image[0].url}
          className=""
          loading="eager"
        />
      )}
    <div className="hero-overlay">
      <div className="container">
        <div className="hero-title-wrap">
          <div className="hero-content">
            <h1>{heading}</h1>
            <h2 className="w-xl-70 mx-xl-auto">{subheading}</h2>
            {children}
          </div>
        </div>
      </div>

      <div className="scroll-link-wrap">
        <span className=" scroll-down-arrow"></span>
        {callToAction && (
          <a href={callToActionLink} className="">
            {callToAction}
          </a>
        )}
      </div>

      <div className="large-down-arrow">
        <svg
          id="Layer_1"
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          height="100%"
          width="100%"
          viewBox="0 0 1442 94.67"
          preserveAspectRatio="none"
        >
          <polygon
            id="Arrow_Mask"
            data-name="Arrow Mask"
            fill="#fff"
            className="cls-1"
            points="1441.5 94.17 0.5 94.17 0.5 0.56 721 84.65 1441.5 0.56 1441.5 94.17"
          ></polygon>
        </svg>
      </div>
    </div>
  </section>
)

HeroBespoke.propTypes = {
  heading: PropTypes.string,
  subheading: PropTypes.string,
  children: PropTypes.node,
  image: PropTypes.object,
  callToAction: PropTypes.string,
  callToActionLink: PropTypes.string,
}

HeroBespoke.defaultProps = {
  heading: `Explore`,
  subheading: `systems and components that make up modern dairy`,
  children: <p></p>,
  image: {
    extension: "jpg",
    filename: "150-farm.jpg",
    focalPoint: [0.5, 0.5],
    hasFocalPoint: false,
    path: "150-farm.jpg",
    title: "150 farm",
    width: 3840,
    url: "//virtual-farm:8890/uploads/150-farm.jpg",
    srcset:
      "//virtual-farm:8890/uploads/_600xAUTO_crop_center-center_none/150-farm.jpg 600w, //virtual-farm:8890/uploads/_900xAUTO_crop_center-center_none/150-farm.jpg 900w, //virtual-farm:8890/uploads/_1200xAUTO_crop_center-center_none/150-farm.jpg 1200w, //virtual-farm:8890/uploads/_1500xAUTO_crop_center-center_none/150-farm.jpg 1500w, //virtual-farm:8890/uploads/_1800xAUTO_crop_center-center_none/150-farm.jpg 1800w",
  },
  callToAction: `Click`,
  callToActionLink: `/`,
}

export default HeroBespoke
