// a wrapper component to conditionally include any component found in the data
// if a user in the craft cms backend added a block from the content builder,
// it will be found in the data and the corresponding component will be rendered

import * as React from "react"
// import PropTypes from 'prop-types';

import StyledTextWrap from "../psu-brand/styled-text-wrap"
import HeroBespoke from "../psu-brand/hero-bespoke"
import HeroFeatureStory from "../psu-brand/hero-feature-story"
import DesignedBlock from "../psu-brand/designed-block"
import ButtonBlock from "../psu-brand/button-block"
import ContentBlock from "../psu-brand/content-block"
import LeadBlock from "../psu-brand/lead-block"
import QuickFacts from "../psu-brand/quick-facts"
import HighlightedVideoPlus from "../psu-brand/highlighted-video-plus"
import Attachment from "../psu-brand/attachment"

const ContentBuilder = props => (
  <>
    {/* {console.log('content builder props: ', props)} */}
    {/* props is the entry itself and props.contentBuilder is a matrix field so we are mapping over the blocks in the matrix field */}
    {typeof props.contentBuilder !== "undefined"
      ? props.contentBuilder.map(component => {
          // html content component
          // console.log(component.typeHandle);
          switch (component.typeHandle) {
            case "heroBespoke":
              return <HeroBespoke {...component} key={component.id} />

            case "heroFeatureStory":
              return (
                <HeroFeatureStory
                  {...component}
                  {...props}
                  key={component.id}
                />
              )

            case "designedBlock":
              return <DesignedBlock {...component} key={component.id} />

            case "contentBlock":
              return (
                <StyledTextWrap>
                  <ContentBlock {...component} key={component.id} />
                </StyledTextWrap>
              )

            case "buttonBlock":
              return (
                <StyledTextWrap>
                  <ButtonBlock {...component} key={component.id} />
                </StyledTextWrap>
              )

            case "leadBlock":
              return (
                <StyledTextWrap>
                  <LeadBlock {...component} key={component.id} />
                </StyledTextWrap>
              )

            case "quickFacts":
              return <QuickFacts {...component} key={component.id} />

            case "videoEntry":
              return <HighlightedVideoPlus {...component} key={component.id} />

            case "attachment":
              return (
                <StyledTextWrap>
                  <Attachment {...component} key={component.id} />
                </StyledTextWrap>
              )

            default:
              return (
                <StyledTextWrap>
                  <p>Add some blocks to the Content Builder.</p>
                </StyledTextWrap>
              )
          }
        })
      : ""}
  </>
)

ContentBuilder.propTypes = {}

ContentBuilder.defaultProps = {}

export default ContentBuilder
