import React, { useState, useRef, useEffect } from 'react';
// import PropTypes from 'prop-types';

// import HtmlContent
import StyledTextWrap from '../psu-brand/styled-text-wrap';
// import HeroBespoke from '../psu-brand/hero-bespoke';
// import HeroFeatureStory from '../psu-brand/hero-feature-story';
// import DesignedBlock from '../psu-brand/designed-block';
// import ButtonBlock from '../psu-brand/button-block';
// import ContentBlock from '../psu-brand/content-block';
import LeadBlock from '../psu-brand/lead-block';
// import QuickFacts from '../psu-brand/quick-facts';
import CraftImage from './craft-image';
// import Container from 'react-bootstrap/Container';
// import Row from 'react-bootstrap/Row';
// import Col from 'react-bootstrap/Col';
import './category-browser.scss';
import parse from 'html-react-parser';
// import Draggable from 'react-draggable';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Modal from 'react-bootstrap/Modal';
import Button from '../psu-brand/button';
import BSButton from 'react-bootstrap/Button';
import { AiFillCloseCircle } from '@react-icons/all-files/ai/AiFillCloseCircle';
import ContentBuilder from './content-builder';

const CategoryBrowser = (props) => {
	const [showOutline, setShowOutline] = useState({});
	function handleMouseEnter(evt) {
		// dynamic property names
		// https://stackoverflow.com/questions/59347700/how-to-create-dynamic-variables-and-use-them-with-usestate
		setShowOutline({
			[evt.target.id]: {
				value: true
			}
		});
		// console.log('showOutline evt: ', evt.target.id);
		// console.log('showOutline: ', showOutline);
	}
	function handleMouseLeave(evt) {
		setShowOutline({
			[evt.target.id]: {
				value: false
			}
		});
		// console.log('showOutline evt: ', evt.target.id);
		// console.log('showOutline: ', showOutline);
	}
	const categoryButtons = useRef(
		props.categories.map((cat) => {
			return (
				<button
					// className={`w-100 text-center button-${cat.color} button-light-bkg mb-3`}
					className={`text-center btn btn-outline-light border mb-3 p-2`}
					// style=""
					text={cat.title}
					// onClick={() => handleCategoryButton(cat.id)}
					// clickHandler={() => handleCategoryButton(cat.id, cat.title)}
					onClick={() => handleCategoryButton(cat.id, cat.title)}
					onMouseEnter={handleMouseEnter}
					onMouseLeave={handleMouseLeave}
					id={'button' + cat.id}
					key={cat.id}
				>
					{cat.title}
				</button>
			);
		})
	);
	// {
	// 	console.log('category browser categoryButtons: ', categoryButtons);
	// }
	// console.log('showOutline: ', showOutline);
	// const [infoArea, setInfoArea] = useState(categoryButtons);
	const [infoArea, setInfoArea] = useState(<>{categoryButtons.current}</>);
	const [selectedCategoryInfo, setSelectedCategoryInfo] = useState(null);
	const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	const handleCategoryButton = (catId, catTitle) => {
		// console.log('handleCategoryButton catId: ', catId);
		const newCatInfo = props.entries
			.filter((entry) => {
				// console.log(
				// 	'handleCategoryButton entry catId: ',
				// 	entry.fastFactCategory[0].id
				// );
				let result =
					entry.fastFactCategory[0].id === catId ? true : false;
				return result;
			})
			.reverse();
		// console.log('newCatInfo: ', newCatInfo);
		setSelectedCategoryInfo(
			<>
				<h1 className="mt-3 mb-4">{catTitle}</h1>
				<Tabs
					variant="pills"
					defaultActiveKey={newCatInfo[0].title.replace(/ /gi, '-')}
					id="cat-tabs"
				>
					{newCatInfo.map((entry) => (
						<Tab
							key={entry.id}
							title={entry.title}
							eventKey={entry.title.replace(/ /gi, '-')}
						>
							<ContentBuilder {...entry} />
						</Tab>
					))}
				</Tabs>
			</>
		);
		setShow(true);
	};

	return (
		<>
			{/* {console.log('category browser props: ', props)} */}

			<div className="category-browser">
				<div className="image-area">
					<div className="zoomContainer">
						{/* <ul>
									{props.interactivePoints.map((point) => (
										<li
											key={point.id}
											style={{
												left: `${point.xValue * 100}%`,
												top: `${point.yValue * 100}%`
											}}
											onClick={() =>
												setDescriptionArea(
													<>
														<h3>
															{point.pointTitle}
														</h3>
														<div>
															{parse(
																point.description
															)}
														</div>
													</>
												)
											}
										>
											<span>{point.pointTitle}</span>
										</li>
									))}
								</ul> */}

						{/* shape overlays */}
						{props.categories.map((cat) => {
							if (
								typeof cat.areaShape !== 'undefined' &&
								cat.areaShape !== null
							) {
								let points = cat.areaShape
									.map(
										(point) =>
											`${
												point.x *
												0.01 *
												props.image.width
											},${
												point.y *
												0.01 *
												props.image.height
											}`
									)
									.join(' ');
								// console.log('points: ', points);
								return (
									// <div
									// 	style={{
									// 		clipPath: `polygon(${points})`
									// 	}}
									// 	className="shape-overlay-container"
									// >
									// 	<div
									// 		style={{
									// 			clipPath: `polygon(${points})`
									// 		}}
									// 		className="shape-overlay"
									// 	></div>
									// </div>
									<svg
										viewBox={`0 0 ${props.image.width} ${props.image.height}`}
										xmlns="http://www.w3.org/2000/svg"
										className={`shape-overlay ${
											typeof showOutline[
												'button' + cat.id
											] !== 'undefined' &&
											showOutline['button' + cat.id].value
												? 'opacity-100'
												: 'opacity-0'
										}`}
										style={{
											backgroundColor: `var(${cat.color})`
										}}
									>
										{/* <!-- Example of the same polygon shape with stroke and no fill --> */}
										<polygon
											points={points}
											strokeWidth="18"
											// filter="url(#sofGlow)"
											style={{
												stroke: `var(--${cat.color})`,
												// fill: `var(--${cat.color}-55)`
												fill: '#ffffff33'
											}}
										/>
									</svg>
								);
							}
						})}
						{/* {console.log('props.imge: ', props.image)} */}
						<CraftImage
							srcset={props.image.srcset}
							alt={props.image.title}
							url={props.image.url}
							className=""
							loading="eager"
						/>
					</div>
				</div>

				<div className="description-area p-5 ">
					{/* {parse(infoArea)} */}
					{infoArea}
				</div>
			</div>
			<div>
				<Modal
					show={show}
					onHide={handleClose}
					dialogClassName="modal-xl"
					// fullscreen={true}
				>
					<AiFillCloseCircle
						onClick={handleClose}
						className="modal-close-icon"
					/>

					<Modal.Body>{selectedCategoryInfo}</Modal.Body>
					<Modal.Footer>
						<BSButton variant="secondary" onClick={handleClose}>
							Close
						</BSButton>
					</Modal.Footer>
				</Modal>
			</div>
		</>
	);
};

CategoryBrowser.propTypes = {};

CategoryBrowser.defaultProps = {};

export default CategoryBrowser;
