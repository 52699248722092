import * as React from "react"
import PropTypes from "prop-types"
import "./styled-text-wrap.scss"
// import parse from 'html-react-parser';

// wrapper for basic page grid/margins like those found here:
// http://staging-static.vmhost.psu.edu/components/non-homepage-hero-short-standard.php
// and here:
// http://staging-static.vmhost.psu.edu/components/image-video-content.php

const StyledTextWrap = function (props) {
  // console.log('children: ', props.children);
  return (
    <section className="styled-text-wrap">
      <div className="container ">
        <div className="styled-text image-video-content">
          <div>{props.children}</div>
        </div>
      </div>
    </section>
  )
}

// StyledTextWrap.propTypes = {
// 	// Symbol(react.element)
// 	children: PropTypes.symbol
// };

StyledTextWrap.defaultProps = {
  children: <p>Add child nodes please.</p>,
}

export default StyledTextWrap
