import * as React from "react"
import PropTypes from "prop-types"
import "./attachment.scss"
import parse from "html-react-parser"

// lead styles found here:
// http://staging-static.vmhost.psu.edu/components/header-standard.php

const Attachment = ({ document }) => {
  return document.map(item => {
    return (
      <>
        <a href={item.url} className="attachment ms-3 mb-2 d-block">
          {item.title || item.filename} ({item.extension.toUpperCase()})
        </a>
        {/* <div>{JSON.stringify(item)}</div> */}
      </>
    )
  })
}

Attachment.propTypes = {
  lead: PropTypes.string,
}

Attachment.defaultProps = {
  lead: `Add something for your lead `,
}

export default Attachment
