import * as React from "react"
import PropTypes from "prop-types"
import "./content-block.scss"
import parse from "html-react-parser"
import useSiteMetadata from "../../hooks/use-site-metadata"

// basic heading, paragraph, button, list, image and video formatting like those found here:
// http://staging-static.vmhost.psu.edu/components/non-homepage-hero-short-standard.php
// and here:
// http://staging-static.vmhost.psu.edu/components/image-video-content.php

const ContentBlock = ({ contentArea }) => {
  const { siteUrl } = useSiteMetadata()
  return (
    <div className="content-block  fluid-line-height">
      {/* {parse(contentArea.replace(/\/\//g, 'https://'))} */}
      {/* {parse(contentArea.replace(/\/uploads/g, `${siteUrl}/uploads`))} */}
      {parse(contentArea)}
    </div>
  )
}

ContentBlock.propTypes = {
  contentArea: PropTypes.string,
}

ContentBlock.defaultProps = {
  contentArea: `Add something in content area `,
}

export default ContentBlock
