import * as React from 'react';
import PropTypes from 'prop-types';
import './lead-block.scss';
import parse from 'html-react-parser';

// lead styles found here:
// http://staging-static.vmhost.psu.edu/components/header-standard.php

const LeadBlock = ({ lead }) => <p className="lead">{parse(lead)}</p>;

LeadBlock.propTypes = {
	lead: PropTypes.string
};

LeadBlock.defaultProps = {
	lead: `Add something for your lead `
};

export default LeadBlock;
