import * as React from 'react';
import PropTypes from 'prop-types';
import './lead-block.scss';
import parse from 'html-react-parser';
import StyledTextWrap from './styled-text-wrap';

// styles found here:
// http://staging-static.vmhost.psu.edu/components/highlighted-video-plus.php

const HighlightedVideoPlus = (props) => {
	// console.log('highlighted video props: ', props);
	// console.log('concat video builder: ', props.video[0].videoBuilder.flat());
	let title = props.video[0].title;
	let videoSrc,
		videoId,
		summary,
		description,
		hostingService,
		altText,
		image = '';
	props.video[0].videoBuilder.forEach((block) => {
		switch (block.typeHandle) {
			case 'videoDescription':
				summary = block.summary;
				description = block.description;
				break;
			case 'hostedVideo':
				hostingService = block.hostingService;
				videoId = block.videoId;
				break;
			case 'videoPoster':
				altText = block.altText;
				image = block.image;
				break;

			default:
				break;
		}
	});
	if (hostingService === 'youtube') {
		// set the video src to autoplay and not to show related video. Youtube related video is like a box of chocolates... you never know what you're gonna get
		videoSrc = `https://www.youtube.com/embed/${videoId}?autoplay=0&amp;modestbranding=1&amp;showinfo=0`;
	}
	if (hostingService === 'vimeo') {
		videoSrc = `https://player.vimeo.com/video/${videoId}?color=c9ff23&title=0&byline=0&portrait=0`;
	}

	return (
		<section className="highlighted-video-plus">
			<StyledTextWrap>
				<h2>{title}</h2>
				{parse(description)}
				<div className="ratio ratio-16x9">
					<iframe
						title={title}
						className="embed-responsive-item"
						src={videoSrc}
						id="video-iframe"
						allowscriptaccess="always"
						allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
						allowfullscreen
					></iframe>
				</div>
			</StyledTextWrap>
		</section>
	);
};

HighlightedVideoPlus.propTypes = {
	lead: PropTypes.string
};

HighlightedVideoPlus.defaultProps = {};

export default HighlightedVideoPlus;
