import React, { useState } from 'react';
// import PropTypes from 'prop-types';

// import HtmlContent
// import StyledTextWrap from '../psu-brand/styled-text-wrap';
// import HeroBespoke from '../psu-brand/hero-bespoke';
// import HeroFeatureStory from '../psu-brand/hero-feature-story';
// import DesignedBlock from '../psu-brand/designed-block';
// import ButtonBlock from '../psu-brand/button-block';
// import ContentBlock from '../psu-brand/content-block';
// import LeadBlock from '../psu-brand/lead-block';
// import QuickFacts from '../psu-brand/quick-facts';
import CraftImage from './craft-image';
// import Container from 'react-bootstrap/Container';
// import Row from 'react-bootstrap/Row';
// import Col from 'react-bootstrap/Col';
import './interactive-image-builder.scss';
import parse from 'html-react-parser';
// import Draggable from 'react-draggable';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
// import Tabs from 'react-bootstrap/Tabs';
// import Tab from 'react-bootstrap/Tab';

const InteractiveImageBuilder = (props) => {
	const [descriptionArea, setDescriptionArea] = useState(
		parse(props.description)
	);
	const [zoom, setZoom] = useState(1);

	let displayImageTitle =
		typeof props.displayImageTitle === 'undefined'
			? true
			: props.displayImageTitle;
	return (
		<>
			{/* {console.log('interactive image builder props: ', props)} */}
			{displayImageTitle ? <h2>{props.imageTitle}</h2> : ''}

			<div className="interactive-image-builder">
				<div className="image-area">
					{/* <Draggable> */}
					<TransformWrapper wheel={{ step: 0.02 }}>
						<div className="tools">
							{/* <button onClick={() => zoomIn()}>+</button>
							<button onClick={() => zoomOut()}>-</button>
							<button onClick={() => resetTransform()}>x</button> */}
						</div>
						<TransformComponent>
							<div
								className="zoomContainer"
								draggable
								style={{ transform: `scale(${zoom})` }}
							>
								<ul className="m-0">
									{props.interactivePoints.map((point) => (
										<li
											key={point.id}
											style={{
												left: `${point.xValue * 100}%`,
												top: `${point.yValue * 100}%`,
												zIndex: `${
													100 - point.xValue * 100
												}`
											}}
											onClick={() =>
												setDescriptionArea(
													<>
														<h3>
															{point.pointTitle}
														</h3>
														<div>
															{parse(
																point.description
															)}
														</div>
													</>
												)
											}
										>
											<span>{point.pointTitle}</span>
										</li>
									))}
								</ul>
								<CraftImage
									srcset={props.image[0].srcset}
									alt={props.image[0].title}
									url={props.image[0].url}
									className=""
									loading="eager"
								/>
							</div>
						</TransformComponent>
					</TransformWrapper>
					{/* </Draggable> */}
					{/* <div className="zoomControl">
						<button onClick={() => setZoom(zoom - 0.1)}>-</button>
						<button onClick={() => setZoom(1)}>Reset</button>
						<button onClick={() => setZoom(zoom + 0.1)}>+</button>
					</div> */}
				</div>

				<div className="description-area ratio ratio-1x1">
					<div className="p-4 ps-5" style={{ overflowY: 'scroll' }}>
						{/* {parse(descriptionArea)} */}
						{descriptionArea}
					</div>
				</div>
			</div>
		</>
	);
};

InteractiveImageBuilder.propTypes = {};

InteractiveImageBuilder.defaultProps = {};

export default InteractiveImageBuilder;
