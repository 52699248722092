import React, { useRef, useEffect } from "react"
// import { graphql, Link } from 'gatsby';
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
// import ButtonChecked from '../components/psu-brand/button-checked';
import StyledTextWrap from "../components/psu-brand/styled-text-wrap"
// import { BsCheck } from '@react-icons/all-files/bs/BsCheck'; // https://react-icons.github.io/react-icons
import ContentBuilder from "../components/craftcms/content-builder"
import ContentBuilderQuery from "../graphql-fragments/content-builder-query"
import InteractiveImageBuilder from "../components/craftcms/interactive-image-builder"
// import InteractiveImageBuilderQuery from "../graphql-fragments/interactive-image-builder-query"
import CategoryBrowser from "../components/craftcms/category-browser"
import CategoryGhgCalculator from "../components/craftcms/category-ghg-calculator"
import Tabs from "react-bootstrap/Tabs"
import Tab from "react-bootstrap/Tab"
// import CraftImage from '../components/craftcms/craft-image';
// import Button from '../components/psu-brand/button';
import "../components/psu-brand/button-color-coded.scss"

const VirtualFarm = ({ location, data }) => {
  // requirements:
  // map image is responsive but not cropped at any point
  // map image is zoomable via plus and minus controls
  // map image is dragable when zoomed in
  // // map image is zoomable via scroll - I don't like this feature
  // markers on map are clickable and change info in a box on the screen
  // tabs at top can toggle between 1500 cow and 150 cow farm

  // keep track of selected tags/items in state
  // const [items, setItems] = useState(data.craftAPI.entries);
  let isMounted = useRef(false)

  // render entries for individually selected tags
  // const filterItems = useCallback(() => {
  useEffect(() => {
    // prevent from running on initial mount so all entries/tags can be displayed
    if (isMounted.current) {
    } else {
      isMounted.current = true
    }
    // fetch(()=>{something})
  }, [data.craftAPI.entry])

  return (
    <Layout location={location} className="page-vf">
      <Seo title="Virtual Farm" />
      {/* <StyledTextWrap></StyledTextWrap> */}
      {/* <ContentBuilder {...data.craftAPI.entry} /> */}
      <Tabs
        defaultActiveKey={data.craftAPI.entry.interactiveImageBuilder[0].imageTitle.replace(
          / /gi,
          "-"
        )}
        id="uncontrolled-tab-example"
        className="m-3 mt-5"
        variant="pills"
      >
        {data.craftAPI.entry.interactiveImageBuilder.map(
          interactiveImageBlock => (
            <Tab
              key={interactiveImageBlock.id}
              eventKey={interactiveImageBlock.imageTitle.replace(/ /gi, "-")}
              title={interactiveImageBlock.imageTitle}
              tabClassName=""
            >
              <InteractiveImageBuilder
                {...interactiveImageBlock}
                key={interactiveImageBlock.id}
                displayImageTitle={false}
              />
            </Tab>
          )
        )}
        <Tab key="area-facts" eventKey="area-facts" title="Fast Facts">
          {/* category browser to go here */}
          {/* {data.craftAPI.categories.map((cat) => (
								<Button
									className="w-100 text-center"
									style="button-light-bkg"
									text={cat.title}
								/>
							))} */}
          <CategoryBrowser
            categories={data.craftAPI.categories}
            image={data.craftAPI.entry.interactiveImageBuilder[1].image[0]}
            entries={data.craftAPI.entries}
          />
        </Tab>
        <Tab key="calculator" eventKey="calculator" title="GHG Calculator">
          <CategoryGhgCalculator
            categories={data.craftAPI.categories}
            image={data.craftAPI.entry.interactiveImageBuilder[1].image[0]}
          />
        </Tab>
      </Tabs>
      <br />
      <br />
      <ContentBuilder {...data.craftAPI.entry} />

      {/* {data.craftAPI.entry.id} */}
      {/* {console.log(data.craftAPI.entry)} */}
    </Layout>
  )
}

export const query = graphql`
  query VirtualFarmPageQuery {
    craftAPI {
      categories(group: "fastFactCategories") {
        id
        title
        ... on CraftAPI_fastFactCategories_Category {
          color
          areaShape {
            x
            y
          }
        }
      }
      entries(section: "fastFacts") {
        id
        title
        ... on CraftAPI_fastFacts_default_Entry {
          id
          contentBuilder {
            ...ContentBuilderQuery
          }
          fastFactCategory {
            id
          }
        }
      }
      entry(section: "virtualFarm") {
        id
        ... on CraftAPI_virtualFarm_virtualFarm_Entry {
          id
          contentBuilder {
            ...ContentBuilderQuery
          }
          interactiveImageBuilder {
            ...InteractiveImageBuilderQuery
          }
        }
      }
    }
  }
`

export default VirtualFarm
