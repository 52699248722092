import React, { useState, useEffect } from 'react';
// import PropTypes from 'prop-types';

// import HtmlContent
import CraftImage from './craft-image';
// import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './category-ghg-calculator.scss';
// import Draggable from 'react-draggable';
// import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';  s
// import Tabs from 'react-bootstrap/Tabs';
// import Tab from 'react-bootstrap/Tab';
import Dropdown from 'react-bootstrap/Dropdown';
import BSButton from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { GiWheat } from '@react-icons/all-files/gi/GiWheat';
import { GiCow } from '@react-icons/all-files/gi/GiCow';
import { GiFarmTractor } from '@react-icons/all-files/gi/GiFarmTractor';
import { FaPoop } from '@react-icons/all-files/fa/FaPoop';
import { FaInfoCircle } from '@react-icons/all-files/fa/FaInfoCircle';
import { ImPlus } from '@react-icons/all-files/im/ImPlus';
import { TiEquals } from '@react-icons/all-files/ti/TiEquals';

// import ContentBuilder from './content-builder';

const CategoryGhgCalculator = (props) => {
	const modelData = require('../../content/data/model-data.json');
	const [showOutline, setShowOutline] = useState({});
	const [forageRatio, setForageRatio] = useState('40/60');
	const [energyRatio, setEnergyRatio] = useState('50% grid, 50% gas/diesel');
	const [manureStorage, setManureStorage] = useState(
		'Covered anaerobic lagoon'
	);
	const [emissionsCrop, setEmissionsCrop] = useState(
		modelData['40/60']['50% grid, 50% gas/diesel'][
			'Covered anaerobic lagoon'
		]['Crop Production emissions']
	);
	const [emissionsFeed, setEmissionsFeed] = useState(
		modelData['40/60']['50% grid, 50% gas/diesel'][
			'Covered anaerobic lagoon'
		]['Feed On-Farm emissions']
	);
	const [emissionsEnergy, setEmissionsEnergy] = useState(
		modelData['40/60']['50% grid, 50% gas/diesel'][
			'Covered anaerobic lagoon'
		]['On-Farm Energy Emissions']
	);
	const [emissionsManure, setEmissionsManure] = useState(
		modelData['40/60']['50% grid, 50% gas/diesel'][
			'Covered anaerobic lagoon'
		]['Manure Management emissions']
	);
	const [emissionsTotal, setEmissionsTotal] = useState(
		modelData['40/60']['50% grid, 50% gas/diesel'][
			'Covered anaerobic lagoon'
		]['Daily adjusted farm emissions']
	);

	function handleMouseEnter(evt) {
		// dynamic property names
		// https://stackoverflow.com/questions/59347700/how-to-create-dynamic-variables-and-use-them-with-usestate
		setShowOutline({
			[evt.target.id]: {
				value: true
			}
		});
		// console.log('showOutline evt: ', evt.target.id);
		// console.log('showOutline: ', showOutline);
	}
	function handleMouseLeave(evt) {
		setShowOutline({
			[evt.target.id]: {
				value: false
			}
		});
		// console.log('showOutline evt: ', evt.target.id);
		// console.log('showOutline: ', showOutline);
	}

	// function updateEmissions() {
	// 	setEmissionsTotal(
	// 		modelData[forageRatio][energyRatio][manureStorage][
	// 			'Daily adjusted farm emissions'
	// 		]
	// 	);
	// 	console.log(
	// 		`emissions combination: ${forageRatio}, ${energyRatio}, ${manureStorage}`
	// 	);
	// }
	// const categoryButtons = useRef(<></>);
	// {
	// 	console.log('category browser categoryButtons: ', categoryButtons);
	// }
	// console.log('showOutline: ', showOutline);
	// const [infoArea, setInfoArea] = useState(categoryButtons);
	// const [infoArea, setInfoArea] = useState(<>{categoryButtons.current}</>);
	// const [selectedCategoryInfo, setSelectedCategoryInfo] = useState(null);
	// const [show, setShow] = useState(false);

	const numberFormatter = new Intl.NumberFormat('en-US', {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2
	});
	const popover = {};
	useEffect(() => {
		let selectedRecord = modelData[forageRatio][energyRatio][manureStorage];
		setEmissionsCrop(
			numberFormatter.format(selectedRecord['Crop Production emissions'])
		);
		setEmissionsFeed(
			numberFormatter.format(selectedRecord['Feed On-Farm emissions'])
		);
		setEmissionsEnergy(
			numberFormatter.format(selectedRecord['On-Farm Energy Emissions'])
		);
		setEmissionsManure(
			numberFormatter.format(
				selectedRecord['Manure Management emissions']
			)
		);
		setEmissionsTotal(
			numberFormatter.format(
				selectedRecord['Daily adjusted farm emissions']
			)
		);
		console.log(
			`emissions combination: ${forageRatio}, ${energyRatio}, ${manureStorage}`
		);
	}, [forageRatio, energyRatio, manureStorage]);

	return (
		<>
			{/* {console.log('category browser props: ', props)} */}

			<div className="category-ghg-calculator">
				<Row className="ghg-info">
					<Col xs={5} sm={6} lg={4}>
						<div className="emission-totals d-flex p-3">
							<h2 className="h5 m-3">Emission Sources</h2>
							<div>
								<GiWheat
									className="area-icon w-20 m-2"
									style={{ height: '4rem' }}
								></GiWheat>
								<h3 className="m-2">Crop Production</h3>
								<p
									data-aos="fade-in"
									className="area-emissions"
								>
									{emissionsCrop}
								</p>
							</div>
							<ImPlus className="area-operators" />
							<div>
								<GiCow
									className="area-icon w-20 m-2"
									style={{ height: '4rem' }}
								></GiCow>
								<h3 className="m-2">Enteric Emissions</h3>
								<p
									data-aos="fade-in"
									className="area-emissions"
								>
									{emissionsFeed}
								</p>
							</div>
							<ImPlus className="area-operators" />
							<div>
								<GiFarmTractor
									className="area-icon w-20 m-2"
									style={{ height: '4rem' }}
								></GiFarmTractor>
								<h3 className="m-2">On-Farm Energy</h3>
								<p
									data-aos="fade-in"
									className="area-emissions"
								>
									{emissionsEnergy}
								</p>
							</div>
							<ImPlus className="area-operators" />
							<div>
								<FaPoop
									className="area-icon w-20 m-2 mt-3"
									style={{ height: '3rem' }}
								></FaPoop>
								<h3 className="m-2">Manure Emissions</h3>
								<p
									data-aos="fade-in"
									className="area-emissions"
								>
									{emissionsManure}
								</p>
							</div>

							<div>
								<TiEquals className="area-icon w-20 m-2 equals" />
								<h3>Total Emissions</h3>
								<p
									data-aos="fade-in"
									className="area-emissions sum-total"
								>
									{emissionsTotal}
								</p>
								{console.log(
									'emissionsTotal: ',
									emissionsTotal
								)}
							</div>
						</div>
					</Col>

					<Col xs={7} sm={6} lg={8}>
						<div className="description-area p-5 pt-3">
							<h2 className="h5 m-3">Management Decisions</h2>
							{props.categories.map((cat) => {
								popover[cat.id] = (
									<Popover id="popover-basic">
										{/* <Popover.Header as="h3">
									Popover right
								</Popover.Header> */}
										{cat.title === 'Cow Life Cycle' && (
											<Popover.Body>
												In order to estimate total
												greenhouse gas (GHG) emissions
												for a model dairy farm, specific
												assumptions must inform
												calculations. This model
												reflects a 1500-cow dairy in
												central Pennsylvania with a
												Holstein herd composition of 135
												calves, 200 dry cows, and 1300
												lactating cows.
											</Popover.Body>
										)}
										{cat.title === 'Crop Production' && (
											<Popover.Body>
												Crop production GHG emissions
												are based on required energy and
												fertilizer inputs. Emissions in
												this category depend entirely on
												the selected composition of the
												feed ration, because the choice
												feed determines what to grow
												on-farm.
											</Popover.Body>
										)}
										{cat.title === 'Feed On-farm' && (
											<Popover.Body>
												Enteric methane production in
												cattle depends on the
												composition of the feed ration.
												Primarily, the ratio of forage
												to concentrate can be controlled
												to reduce direct GHG emissions
												from dairy cows.
											</Popover.Body>
										)}
										{cat.title === 'Energy On-farm' && (
											<Popover.Body>
												Running a milking parlor,
												keeping cows cool, and caring
												for a herd requires significant
												energy use. The allocation of
												energy from fossil fuel and
												renewable sources used on-farm
												influences farm GHG emissions.
											</Popover.Body>
										)}
										{cat.title === 'Manure Management' && (
											<Popover.Body>
												Based on the composition of the
												feed ration, cattle will produce
												a certain quantity of manure,
												which emits the GHGs methane and
												nitrous oxide. Different storage
												options for manure can limit the
												direct emissions of these GHGs.
											</Popover.Body>
										)}
									</Popover>
								);

								return (
									<>
										{cat.title === 'Cow Life Cycle' && (
											<Row
												className={`p-2 x-button-${cat.color}`}
												onMouseEnter={handleMouseEnter}
												onMouseLeave={handleMouseLeave}
												id={'button' + cat.id}
											>
												<Col classname="py-3">
													<h3>
														{cat.title}
														<OverlayTrigger
															key={cat.id}
															trigger="click"
															placement="bottom"
															overlay={
																popover[cat.id]
															}
														>
															<BSButton
																variant="link"
																className="p-2"
															>
																<FaInfoCircle></FaInfoCircle>
															</BSButton>
														</OverlayTrigger>
													</h3>
												</Col>
											</Row>
										)}
										{cat.title === 'Crop Production' && (
											<Row
												className={`p-2 x-button-${cat.color}`}
												onMouseEnter={handleMouseEnter}
												onMouseLeave={handleMouseLeave}
												id={'button' + cat.id}
											>
												<Col classname="py-3">
													<h3>
														{cat.title}
														<OverlayTrigger
															key={cat.id}
															trigger="click"
															placement="bottom"
															overlay={
																popover[cat.id]
															}
														>
															<BSButton
																variant="link"
																className="p-2"
															>
																<FaInfoCircle></FaInfoCircle>
															</BSButton>
														</OverlayTrigger>
													</h3>
												</Col>
											</Row>
										)}
										{cat.title === 'Feed On-farm' && (
											<Row
												className={`p-2 x-button-${cat.color}`}
												onMouseEnter={handleMouseEnter}
												onMouseLeave={handleMouseLeave}
												id={'button' + cat.id}
											>
												<Col classname="py-3">
													<h3>
														{cat.title}
														<OverlayTrigger
															key={cat.id}
															trigger="click"
															placement="bottom"
															overlay={
																popover[cat.id]
															}
														>
															<BSButton
																variant="link"
																className="p-2"
															>
																<FaInfoCircle></FaInfoCircle>
															</BSButton>
														</OverlayTrigger>
													</h3>

													<label className="fluid-type-sm">
														Forage to Concentrate
														Ratio
													</label>
													<Dropdown>
														<Dropdown.Toggle
															variant="secondary"
															id="dropdown-basic"
														>
															{forageRatio}
														</Dropdown.Toggle>

														<Dropdown.Menu>
															{[
																'40/60',
																'45/55',
																'50/50',
																'55/45',
																'60/40'
															].map((ratio) => (
																<Dropdown.Item
																	onClick={() => {
																		setForageRatio(
																			ratio
																		);
																		// updateEmissions();
																	}}
																	// href="#/action-1"
																>
																	{ratio}
																</Dropdown.Item>
															))}
														</Dropdown.Menu>
													</Dropdown>
													{/* <p className="h5 w-100 my-3 text-start">
												{forageRatio}
											</p> */}
												</Col>
											</Row>
										)}
										{cat.title === 'Energy On-farm' && (
											<Row
												className={`p-2 x-button-${cat.color}`}
												onMouseEnter={handleMouseEnter}
												onMouseLeave={handleMouseLeave}
												id={'button' + cat.id}
											>
												<Col classname="py-3">
													<h3>
														{cat.title}
														<OverlayTrigger
															key={cat.id}
															trigger="click"
															placement="bottom"
															overlay={
																popover[cat.id]
															}
														>
															<BSButton
																variant="link"
																className="p-2"
															>
																<FaInfoCircle></FaInfoCircle>
															</BSButton>
														</OverlayTrigger>
													</h3>
													<label className="fluid-type-sm">
														Energy Profile
													</label>
													<Dropdown>
														<Dropdown.Toggle
															variant="secondary"
															id="dropdown-basic"
														>
															{energyRatio}
														</Dropdown.Toggle>

														<Dropdown.Menu>
															{[
																'50% grid, 50% gas/diesel',
																'60% grid, 40% gas/diesel',
																'60% grid, 40% gas/diesel',
																'50% grid, 40% gas/diesel, 10% solar',
																'10% grid, 30% gas/diesel, 60% solar'
															].map((eRatio) => (
																<Dropdown.Item
																	onClick={() => {
																		setEnergyRatio(
																			eRatio
																		);
																		// updateEmissions();
																	}}
																	// href="#/action-1"
																>
																	{eRatio}
																</Dropdown.Item>
															))}
														</Dropdown.Menu>
													</Dropdown>
												</Col>
											</Row>
										)}
										{cat.title === 'Manure Management' && (
											<Row
												className={`p-2 x-button-${cat.color} last`}
												onMouseEnter={handleMouseEnter}
												onMouseLeave={handleMouseLeave}
												id={'button' + cat.id}
											>
												<Col classname="py-3">
													<h3>
														{cat.title}
														<OverlayTrigger
															key={cat.id}
															trigger="click"
															placement="bottom"
															overlay={
																popover[cat.id]
															}
														>
															<BSButton
																variant="link"
																className="p-2"
															>
																<FaInfoCircle></FaInfoCircle>
															</BSButton>
														</OverlayTrigger>
													</h3>

													<label className="fluid-type-sm">
														Manure Storage Type
													</label>
													<Dropdown>
														<Dropdown.Toggle
															variant="secondary"
															id="dropdown-basic"
														>
															{manureStorage}
														</Dropdown.Toggle>

														<Dropdown.Menu>
															{[
																'Covered anaerobic lagoon',
																'Uncovered anaerobic lagoon',
																'Liquid slurry pond',
																'Solid storage'
															].map(
																(mStorage) => (
																	<Dropdown.Item
																		onClick={() => {
																			setManureStorage(
																				mStorage
																			);
																			// updateEmissions();
																		}}
																		// href="#/action-1"
																	>
																		{
																			mStorage
																		}
																	</Dropdown.Item>
																)
															)}
														</Dropdown.Menu>
													</Dropdown>
												</Col>
											</Row>
										)}
									</>
								);
							})}
						</div>
					</Col>
				</Row>
				<div className="image-area">
					<div className="zoomContainer">
						{/* shape overlays */}
						{props.categories.map((cat) => {
							if (
								typeof cat.areaShape !== 'undefined' &&
								cat.areaShape !== null
							) {
								let points = cat.areaShape
									.map(
										(point) =>
											`${
												point.x *
												0.01 *
												props.image.width
											},${
												point.y *
												0.01 *
												props.image.height
											}`
									)
									.join(' ');
								// console.log('points: ', points);
								return (
									<svg
										viewBox={`0 0 ${props.image.width} ${props.image.height}`}
										xmlns="http://www.w3.org/2000/svg"
										className={`shape-overlay ${
											typeof showOutline[
												'button' + cat.id
											] !== 'undefined' &&
											showOutline['button' + cat.id].value
												? 'opacity-100'
												: 'opacity-0'
										}`}
										style={{
											backgroundColor: `var(${cat.color})`
										}}
									>
										{/* <!-- Example of the same polygon shape with stroke and no fill --> */}
										<polygon
											points={points}
											strokeWidth="18"
											// filter="url(#sofGlow)"
											style={{
												stroke: `var(--${cat.color})`,
												// stroke: `var(--white)`,
												fill: `var(--${cat.color}-55)`
												// fill: '#ffffff33'
											}}
										/>
									</svg>
								);
							}
						})}
						{/* {console.log('props.imge: ', props.image)} */}
						<CraftImage
							srcset={props.image.srcset}
							alt={props.image.title}
							url={props.image.url}
							className=""
							loading="eager"
						/>
					</div>
				</div>
			</div>
		</>
	);
};

CategoryGhgCalculator.propTypes = {};

CategoryGhgCalculator.defaultProps = {};

export default CategoryGhgCalculator;

{
	/* props.categories.map((cat) => {
			return (

				<button
					className={`w-100 text-center x-button-${cat.color} button-light-bkg mb-3`}
					// style=""
					text={cat.title}
					// onClick={() => handleCategoryButton(cat.id)}
					// clickHandler={() => handleCategoryButton(cat.id, cat.title)}
					onClick={() => handleCategoryButton(cat.id, cat.title)}
					onMouseEnter={handleMouseEnter}
					onMouseLeave={handleMouseLeave}
					id={'button' + cat.id}
					key={cat.id}
				>
					{cat.title}
				</button>
			);
		}) */
}
