import * as React from 'react';
// import Button from './button';
import './quick-facts.scss';
import parse from 'html-react-parser';

function QuickFacts(props) {
	return (
		<>
			<section className="facts-wrap">
				<div className="container ">
					<div className="facts">
						<div className="facts-intro">
							{/* {console.log('quick facts props: ', props)} */}
							<h2>{props.heading}</h2>

							<p>{parse(props.description)}</p>
						</div>

						<div className="four-column-facts">
							{props.statistics.map((stat) => {
								// console.log('button: ', button);

								return (
									<div>
										<h3>{stat.statisticValue}</h3>
										<b></b>
										<p>{stat.statisticText}</p>
									</div>
								);
							})}
						</div>
					</div>
				</div>
			</section>

			{/* {buttons.map((button) => {
				// console.log('button: ', button);

				return (
					<div>
						<h3>Typ 50</h3>
						<b></b>
						<p>
							World-Class University (Center For World University
							Rankings)
						</p>
					</div>
				);
			})} */}
		</>
	);
}

export default QuickFacts;
