import * as React from 'react';
import Button from './button';
import './button.scss';

function ButtonBlock({ id, buttons, className }) {
	// console.log('buttons: ', buttons);
	return (
		<>
			{buttons.map((button) => {
				// console.log('button: ', button);

				return (
					<Button {...button} className="me-3 mb-3" key={button.id} />
				);
			})}
		</>
	);
}

export default ButtonBlock;
